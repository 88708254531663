import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Facebook Advertising',
        description: 'Maximize your reach and drive conversions with our targeted Facebook advertising campaigns. Our team of experts will work with you to create compelling ads that resonate with your audience and drive results.'
    },
    {
        icon: <FiLayers />,
        title: 'Google Optimization',
        description: "Increase your website's visibility on Google and reach more potential customers with our expert SEO services. We'll optimize your website's structure, content, and meta tags to help you rank higher in search engine results."
    },
    {
        icon: <FiUsers />,
        title: 'TikTok Marketing',
        description: 'Tap into the growing audience on TikTok and take your social media marketing to the next level. Our team specializes in creating engaging TikTok content that resonates with your audience and drives conversions.'
    },
    {
        icon: <FiMonitor />,
        title: 'Taboola Advertising',
        description: "Drive traffic to your website and increase brand awareness with Taboola advertising. We'll work with you to create high-quality ads that appear on top publishers' websites and drive conversions."
    },
    {
        icon: <FiLayers />,
        title: 'Copywriting Services',
        description: "Create compelling, high-quality content that engages your audience and drives conversions with our copywriting services. From website copy to blog posts and email marketing, we'll help you develop a content strategy that resonates with your audience."
    },
    {
        icon: <FiCast />,
        title: 'SEO Services',
        description: "Optimize your website's structure, content, and meta tags to increase your visibility and rank higher in search engine results with our SEO services. Our team will work with you to develop a customized strategy that meets your specific needs."
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'we offer a range of customized solutions to help your business thrive online. From social media advertising to SEO optimization and content creation, our team is dedicated to delivering effective results.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                {/* <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
