import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = "At 10X Booster, we understand the unique challenges that small and medium-sized businesses face in today's competitive online marketplace. That's why we offer customized solutions tailored to meet the specific needs of each of our clients.";
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="https://img.freepik.com/free-photo/male-female-graphic-designers-interacting-computer_1170-951.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>We specialize in social media marketing, PPC advertising, SEO optimization, and much more. Our team has the skills and expertise to help you succeed online, no matter what your goals may be.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Let's do it</h3>
                                                <p>Whether you're looking to increase your online presence, generate leads, or boost sales, we have the skills and expertise to help you achieve your goals. Let's work together to take your business to the next level.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;