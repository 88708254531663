import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>"10X Booster helped me transform my small business into a profitable and successful venture. Their customized social media campaigns and SEO optimization strategies really made a difference in my online presence. I highly recommend their services to any small business looking to increase their sales and reach more customers."</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Omar Ramirez </span> - CEO of Muebles M&S Incorporated</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>"Working with 10X Booster has been a game-changer for our company. Their team is dedicated, responsive, and truly understands our business needs. Their creative content and advertising strategies have helped us achieve our goals and grow our customer base. We're thrilled with the results and highly recommend their services."</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>John Custodio</span> - Marketing Manager of The Scale Retail Incorporated</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>"Their expertise in online marketing has been invaluable to our business. Their team is professional, knowledgeable, and truly cares about their clients' success. Their strategic campaigns have helped us increase our online visibility and generate more leads. We're grateful for their partnership and look forward to working with them for years to come.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Mark Johnson</span> - CEO of Petnyco Incorporated</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;